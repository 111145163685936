.main-slider .swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-slider .swiper-pagination-bullet {
  background-color: #ffff;
  margin-right: 14px !important;
  width: 8px;
  height: 8px;
  border: 1px solid black;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.4;
  border-radius: 100px !important;
  border-spacing: 5px;
}

.main-slider .swiper-pagination-bullet::after{
  content: "";
  position: absolute;
  top: -10;
  left: -10;
  padding: 12px;
  display: flex;
  align-content: center;
  border-radius: 50%;
}

.main-slider .swiper-pagination-bullet-active {
  border: none;
  background-color: #919296;
  width: 11px;
  height: 11px;
  margin-right: 15px !important;
  outline: none;
  opacity: 1;
}

.main-slider:not(.main-slider-summary) .swiper-slide {
  height: 100% !important;
}


/* Might need later on */
/* .main-slider.main-slider-configuator .swiper-slide,
.main-slider.main-slider-configuator .swiper-slide.swiper-slide-active {
  height: calc(100vh - 315px);
}

.main-slider.main-slider-interior-slider .swiper-slide,
.main-slider.main-slider-interior-slider .swiper-slide.swiper-slide-active {
  height: calc(100vh - 232px);
}

@media (max-width: 868px) {
  .main-slider.main-slider-configuator .swiper-slide,
  .main-slider.main-slider-configuator .swiper-slide.swiper-slide-active {
    height: calc(100vh - 300px);
  }
}

*/

.main-slider.main-slider-summary .swiper-slide,
.main-slider.main-slider-summary .swiper-slide.swiper-slide-active {
  height: calc(100vh - 230px);
}

.main-slider .swiper-button-prev::after,
.main-slider .swiper-button-next::after {
  content: "";
}

.main-slider .swiper-button-next {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg _ngcontent-uuu-c4='' fill='none' height='17' viewBox='0 0 25 17' width='25' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath _ngcontent-uuu-c4='' d='M0 8.5H24M24 8.5L16.5538 1M24 8.5L16.5538 16' stroke='%231E1F22' stroke-linejoin='bevel'%3E%3C/path%3E%3C/svg%3E");
}

.main-slider .swiper-button-prev.swiper-button-disabled,
.main-slider .swiper-button-next.swiper-button-disabled {
  opacity: 0;
}

.main-slider .swiper-button-prev {
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg _ngcontent-uuu-c4='' fill='none' height='17' viewBox='0 0 25 17' width='25' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath _ngcontent-uuu-c4='' d='M25 8.5H1M1 8.5L8.44615 1M1 8.5L8.44615 16' stroke='%231E1F22' stroke-linejoin='bevel'%3E%3C/path%3E%3C/svg%3E");
}
.swiper-wrapper{
  display: flex !important;
}