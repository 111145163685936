.checkbox {
  &:hover {
    .checkmark {
      background-color: #fff;
    }

    input {
      &:checked {
        .checkmark {
          background-color: transparent;
        }

        .checkmark:after {
          display: block;
        }
      }
    }
  }

  input {
    &:checked {
      ~ .checkmark:after {
        display: block;
      }
    }
  }

  .checkmark {
    &:after {
      content: "";
      border-width: 0 3px 3px 0;
      border-color: #2f74b6;
    }
  }
}

