.variant-item-swiper {
    &--prev {
        background: linear-gradient(-270deg, #F5F5F5 38.64%, rgba(245, 245, 245, 0) 100%);
        &:hover{
            background-image: linear-gradient(to right, rgb(228 228 231),  rgba(255,0,0,0) );
        }
    }
    &--next {
        background: linear-gradient(270deg, #F5F5F5 38.64%, rgba(245, 245, 245, 0) 100%);
        &:hover{
            background-image: linear-gradient(to left, rgb(228 228 231),  rgba(255,0,0,0) );
        }
    }
}

.background-none {
    background-image: none !important;
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.image-360-height {
    height: calc(100vh - 233px);
    width: 100%;
}

.pnlm-container {
    height: 100% !important;
    width: 100%;
}
.pnlm-controls-container{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .pnlm-fullscreen-toggle-button{
        position: relative;
        right: 25px;
        top: 15px;
        border: none !important;
        outline: none !important;
        height: 37px;
        width: 37px;
        background: url("../../../icons/openedFullScreen.svg") !important;
    }
    .pnlm-fullscreen-toggle-button-active{
        background: url("../../../icons/closedFullScreen.svg") !important;
    }
}
