.email-modal{
    background-color: rgba(255, 255, 255, 0.8);
    width: 100vw;
    height: 100%;
    overflow-y: scroll;

    &__content{
        width: 340px;
        box-shadow: 0 3px 20px rgb(0 0 0 / 16%);
        @media screen and (min-width: 640px){
            width: 450px;
        };
        @media screen and (min-width: 768px){
            width: 700px;
        };
        @media screen and (min-width: 1024px){
            width: 900px;
        };
    };

    &__salesforce{
        width: 340px;
        box-shadow: 0 3px 20px rgb(0 0 0 / 16%);
        @media screen and (min-width: 640px){
            width: 450px;
        };
        @media screen and (min-width: 768px){
            width: 700px;
        };
    }
}
.thank-you--modal {
    width: 340px;
    @media screen and (min-width: 640px){
        width: 450px;
    };
    @media screen and (min-width: 768px){
        width: 600px;
    };
}
