.swiper-color {
  height: 130px;
  overflow-y: hidden;
}

.swiper-color .swiper-wrapper {
  align-items: center;
}
.disabled_swiper_button {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

#color-scrollbar::-webkit-scrollbar {
  width: 6px;
}

#color-scrollbar::-webkit-scrollbar-thumb {
  background-color: #C4C4C4;
  border-radius: 20px;
  border: 1px solid #C4C4C4;
  height: 100px !important;
}

.text-font-vonum-bold{
  font-family: 'Volvo Novum Medium';
}